import './ScrollToExploreIcon.sass'

export const ScrollToExploreIcon = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M26 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4ZM26 26H6V6H26V26Z"
                fill="white"/>
            <g id="arrow" clipPath="url(#clip-path)">
                <path
                    d="M20.9246 16.6171C20.8742 16.4957 20.8005 16.3854 20.7075 16.2925C20.6146 16.1995 20.5043 16.1258 20.3829 16.0754C20.2615 16.0251 20.1314 15.9992 20 15.9992C19.8686 15.9992 19.7385 16.0251 19.6171 16.0754C19.4957 16.1258 19.3854 16.1995 19.2925 16.2925L17 18.5863V11C17 10.7348 16.8946 10.4804 16.7071 10.2929C16.5196 10.1054 16.2652 10 16 10C15.7348 10 15.4804 10.1054 15.2929 10.2929C15.1054 10.4804 15 10.7348 15 11V18.5863L12.7075 16.2925C12.5199 16.1049 12.2654 15.9994 12 15.9994C11.7346 15.9994 11.4801 16.1049 11.2925 16.2925C11.1049 16.4801 10.9994 16.7346 10.9994 17C10.9994 17.2654 11.1049 17.5199 11.2925 17.7075L15.2925 21.7075C15.3854 21.8005 15.4957 21.8742 15.6171 21.9246C15.7385 21.9749 15.8686 22.0008 16 22.0008C16.1314 22.0008 16.2615 21.9749 16.3829 21.9246C16.5043 21.8742 16.6146 21.8005 16.7075 21.7075L20.7075 17.7075C20.8005 17.6146 20.8742 17.5043 20.9246 17.3829C20.9749 17.2615 21.0008 17.1314 21.0008 17C21.0008 16.8686 20.9749 16.7385 20.9246 16.6171Z"
                    fill="currentColor"/>
                <path
                    d="M20.9246 -3.38294C20.8742 -3.50434 20.8005 -3.61463 20.7075 -3.7075C20.6146 -3.80048 20.5043 -3.87424 20.3829 -3.92456C20.2615 -3.97488 20.1314 -4.00079 20 -4.00079C19.8686 -4.00079 19.7385 -3.97488 19.6171 -3.92456C19.4957 -3.87424 19.3854 -3.80048 19.2925 -3.7075L17 -1.41375V-9C17 -9.26522 16.8946 -9.51957 16.7071 -9.70711C16.5196 -9.89464 16.2652 -10 16 -10C15.7348 -10 15.4804 -9.89464 15.2929 -9.70711C15.1054 -9.51957 15 -9.26522 15 -9V-1.41375L12.7075 -3.7075C12.5199 -3.89514 12.2654 -4.00056 12 -4.00056C11.7346 -4.00056 11.4801 -3.89514 11.2925 -3.7075C11.1049 -3.51986 10.9994 -3.26536 10.9994 -3C10.9994 -2.73464 11.1049 -2.48014 11.2925 -2.2925L15.2925 1.7075C15.3854 1.80048 15.4957 1.87424 15.6171 1.92456C15.7385 1.97488 15.8686 2.00079 16 2.00079C16.1314 2.00079 16.2615 1.97488 16.3829 1.92456C16.5043 1.87424 16.6146 1.80048 16.7075 1.7075L20.7075 -2.2925C20.8005 -2.38537 20.8742 -2.49566 20.9246 -2.61706C20.9749 -2.73846 21.0008 -2.86858 21.0008 -3C21.0008 -3.13142 20.9749 -3.26154 20.9246 -3.38294Z"
                    fill="currentColor"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip-path">
                <rect width="12" height="20" fill="white" transform="translate(10 6)"/>
            </clipPath>
        </defs>
    </svg>

}